import Typography from '@mui/material/Typography'

function HeaderItem(props) {
  const obj = props.link ? { target: "_blank", rel: "noreferrer noopener" } : {}
  return (
    <Typography variant={props.variant} className={'headerText'}>
      <a href={`${props.link || `#${props.text}`}`} onClick={props.onClickFunc} {...obj}>{props.text}</a>
    </Typography>
  )
}

export default HeaderItem
