import { useEffect } from 'react'
import Header from './components/header'
import BodyText from './components/bodyText'
import Location from './components/location'
import Footer from './components/footer'

function updateHeight(placeholder, footer) {
  // Placeholder should always match footer height
  placeholder.style.marginBottom = `${footer.offsetHeight}px`
}

const renderFooterStyleCorrectly = () => {

  const placeholder = document.querySelector('main')
  const footer = document.querySelector('#footer')

  // On DOMContentLoaded, set placeholder height to be equal to footer height
  updateHeight(placeholder, footer)

  window.addEventListener('resize', () => updateHeight(placeholder, footer))
}

function App() {
  useEffect(() => {
    renderFooterStyleCorrectly();
  }, []);
  return (
    <div className="App">
      <Header />
      <main>
        <BodyText />
        <Location />
      </main>
      <Footer />
    </div>
  )
}

export default App
