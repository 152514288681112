import * as React from 'react';
import {
    GoogleMap,
    Marker,
    LoadScript
} from '@react-google-maps/api'


const center = {
    lat: 47.0296191,
    lng: -122.9264803,
}

const containerStyle = {
    width: '100%',
    height: '400px',
}


const MapWithAMarker = () => (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}
        >
            <Marker position={center} />
        </GoogleMap>
    </LoadScript>
)

export default MapWithAMarker