import * as React from 'react'
import MapWithAMarker from './googleMaps'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Item from './item'
import Header from './headerText'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Location = () => {
  const [state, setState] = React.useState({ message: "", name: '', email: '' });
  const [formError, setFormError] = React.useState("");
  const [sent, setSentBoolean] = React.useState(false)

  const handleChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, message, name } = state;
    if (!email.trim() && !message.trim() && !name.trim()) {
      setFormError("Please check the form for any errors.");
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...state,
      }),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          setState({});
          setSentBoolean(true)
        } else {
          alert("There was an error sending your email, please try again later.");
        }
      })
      .catch((error) => alert(error));
  };

  return (
    <section>
      <Grid
        container
        spacing={2}
        id={'Contact'}
        style={{ textAlign: 'center', }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Item sx={{ margin: 2 }}>
            <MapWithAMarker
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={3} style={{ textAlign: 'center', }}>
          <Header variant="h4" text={"Our Hours"} />
          <List>
            <ListItemText primary="Huicholitos" />
            <ListItemText primary="2125 Caton Way SW, Olympia, WA 98502" />
            <ListItemText primary="(360)-539-8890" />
            <ListItemText primary="Hours" />
            <ListItemText primary="Mon-Fri 11AM - 7:30PM" />
            <ListItemText primary="Sat 11AM - 7:30PM" />
            <ListItemText primary="Sunday - Closed" />
          </List>
        </Grid>
        <Grid item xs={12} sm={12} md={5} style={{ textAlign: 'center', }}>
          <Header variant="h4" text={"Contact Us"} />
          <List>
            <Box component={"form"} name={"contact"} data-netlify className={"formHolder"} sx={{ '& .MuiTextField-root': { m: 1, width: '95%' }, }} onSubmit={handleSubmit}>
              <input type="hidden" name="form-name" value={'contact'} />
              <TextField
                fullWidth
                label={"Full Name"}
                variant={"outlined"}
                name={'name'}
                value={state.name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label={"Email Address"}
                variant={"outlined"}
                name={"email"}
                value={state.email}
                onChange={handleChange}
              />
              <TextField
                label={"Your message"}
                multiline
                name={"message"}
                fullWidth
                rows={5}
                value={state.message}
                onChange={handleChange}
              />
              <Button type={"submit"} className={"submitButton"} disabled={sent}>
                Submit
              </Button>
              {formError && formError}
            </Box>
          </List>
        </Grid>
      </Grid>
    </section>
  )
}

export default Location
