import * as React from 'react'
import Grid from '@mui/material/Grid'
import Hero from './hero'
import AboutUs from './aboutUs'
import Menu from './menu'
import Gallery from './gallery'

// Import Swiper styles
// import placeholderImage from '../images/section-a-bg.jpg'

function BodyText() {
  return (
    <section>
      <Hero />
      <div style={{ marginTop: '10px' }} id={'About'}>
        <Grid
          container
          spacing={2}
          justifyContent={'flex-end'}
          alignItems={'center'}
          className={'orangeBackground'}
        >
          <AboutUs />
          <Grid item xs={12} sm={12} md={12}>
            <Gallery />
          </Grid>
        </Grid>
          <Menu />
      </div>
    </section>
  )
}

export default BodyText
