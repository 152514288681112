import * as React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import { Slider } from './carousel'
import Grid2 from '@mui/material/Unstable_Grid2';
import Header from './headerText'

const Menu = () => {
  return (
    <Grid2
      container
      spacing={2}
      justifyContent={'flex-end'}
      alignItems={'center'}
      id={'Menu'}
      xs={12}
      className={"galleryContainer"}
      style={{ position: "relative" }}>
      <Grid2 item xs={12} sm={12} md={12} textAlign={'center'} style={{ margin: '30px 0 0 0' }} display="flex" justifyContent="center" alignItems="center">
        <ListSubheader component={"div"} style={{ backgroundColor: "#fbfbf9" }}>
          <Header text={"Huicholitos Menu"} variant={"h4"} />
          <a className={"peopleOrange"} target={"noopener"} href={"https://drive.google.com/file/d/1ykVsqhPg4vdtI3IVeHDiGF39SEAhapZK/view?usp=drivesdk"}>Download Our Menu</a>
        </ListSubheader>
      </Grid2>
      <Grid2 container item xs={12} sm={12} md={12} justifyContent={"center"}
        spacing={2}>
        <Slider />
      </Grid2>
    </Grid2 >
  )
}

export default Menu;