import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'

import OutlinedCard from './card'
import Typography from '@mui/material/Typography'

function AboutUs() {
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={5} style={{ textAlign: 'center' }}>
        {/* Make dynamic, grab new word of the day on refresh */}
        <OutlinedCard />
      </Grid>
      <Grid item xs={12} sm={12} md={7} style={{ textAlign: 'center' }}>
        <Box sx={{ margin: 5 }} >
          <Card style={{ backgroundColor: '#D4EDF4' }}>
            <CardContent>
              <Typography variant={"h4"} gutterBottom>
                Un poquito sobre nosotros
                <Divider />
                (A tiny bit about us)
              </Typography>
              <Typography variant={"p"} gutterBottom>
                In the 90s, our family immigrated here from Nayarit with the "American Dream". With 20+ years of kitchen exprience, we had the chance to open our dream restaurant - Huicholitos. With our grand opening in December 2021, we poured heart and soul into running our mom-and-pop shop at the rise of a global pandemic. Located in the middle of a quaint gas station, our priority is offering a home-y flavor with fresh ingredients and creating a close, family-like exprience with our customers. We hope we can share our food with you and expand our services in the future!
              </Typography>
            </CardContent>
            <Divider />
            <CardActions style={{ justifyContent: "center" }}>
              <Button className={"heroButton"} size={"small"}>
                <a href={"https://www.doordash.com/store/23158037?utm_source=mx_share"} target="_blank" rel="noreferrer noopener">Order through DoorDash.</a>
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Grid>
    </React.Fragment >
  )
}

export default AboutUs
